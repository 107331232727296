import * as React from "react";
import { graphql } from 'gatsby';
import Blog, { BlogSubHeader } from '../../components/Blog';
import { Paragraph } from "../../components/Common";
import { HE } from '../../blogs/what-dose-full-stack-developer';
import { WhatDoseFullstackDeveloper, TypicalFrontEndDay, HowToDoSoftwareDesignRight, SoftwareDevelopmentToolsThatMustBeFamiliar } from '../../blogs';
import { useBreadcrumbsNesteadSchema } from '../../hooks/useBreadcrumbsNesteadSchema';
import { BLOGS_ORIGINAL_BREADCRUMBS } from '../../constants';
import { useArticelSchema } from '../../hooks/useArticelSchema';

const articleSchemaData = {
  headline: 'SEO title What Dose Full Stack Developer',
  description: "SEO description What Dose Full Stack Developer",
  image: "https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fwhat-dose-full-stack-developer.jpg?alt=media&token=7df86cc1-d124-4c7a-a377-1e4141151faf",
  datePublished: '2022-03-20'
};

const WhatDoseFullstackDeveloperBlog = () => {
  const breadcrumbsElementsSchema = useBreadcrumbsNesteadSchema(WhatDoseFullstackDeveloper.title, BLOGS_ORIGINAL_BREADCRUMBS);
  const articelSchema = useArticelSchema(articleSchemaData);

  return (
    <Blog
      url={WhatDoseFullstackDeveloper.url}
      relatedBlogs={[HowToDoSoftwareDesignRight, TypicalFrontEndDay, SoftwareDevelopmentToolsThatMustBeFamiliar]}
      banner="/what-dose-full-stack-developer/banner.jpg"
      title={WhatDoseFullstackDeveloper.title}
      seo={{
        title: 'SEO title What Dose Full Stack Developer',
        description: WhatDoseFullstackDeveloper.description,
        image: "https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fwhat-dose-full-stack-developer.jpg?alt=media&token=7df86cc1-d124-4c7a-a377-1e4141151faf",
        breadcrumbsElementsSchema,
        articelSchema,
      }}
    >
      {HE.section1.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section2.h}
      </BlogSubHeader>
      {HE.section2.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section3.h}
      </BlogSubHeader>
      {HE.section3.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section4.h}
      </BlogSubHeader>
      {HE.section4.p.map(p => <Paragraph>{p}</Paragraph>)}
    </Blog>
  )
}

export default WhatDoseFullstackDeveloperBlog;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;