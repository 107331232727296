import React from "react"
import { Link } from 'src/components/Common';
import { URL } from '../url';

export const HE = {
  section1: {
    p: [
      "מפתח Full Stack הוא מפתח תוכנה השולט במגוון טכנולוגיות ושפות פיתוח. מפתח פול סטאק מסוגל לפתח אתרי WEB מורכבים ואפליקציות שלמות, בהתאם למפרטים המתקבלים מאנשי ה-UI/UX, ולמעשה יש לו את כל הידע הנדרש לפיתוח מקצה לקצה. הידע של אנשי פיתוח Full Stack מאפשר להם לפתח רכיבים ואפליקציות צד שרת וצד לקוח, באמצעות שליטה במגוון פלטפורמות ושפות תכנות.",
      <>
        {`בשנים האחרונות החל ביקוש רב למפתחי פול סטאק, ואם מעיינים בלוחות הדרושים מגלים שזהו אחד התחומים המבוקשים ביותר בקרב חברות הייטק וטכנולוגיה. לכן, אין זה פלא כי רבים מתמקדים כיום בלימודי פיתוח Full Stack, והמעסיקים בהייטק מגייסים במרץ אנשי פיתוח המחזיקים בידע הנדרש לפיתוח מקצה לקצה, בדגש על `}
        <Link to={URL.FRONT_END_SERVICE} active>פיתוח Front End</Link>
        {` ו- Back End.`}
        {` יש משרות בהן המתכנת נדרש לפיתוח אפליקציה או אתר WEB מאפס, אך ברוב המשרות מפתחים רכיבי תוכנה קטנים מאפס (א' – ת') ומפתחים פיצ'רים ורכיבים ליישום קיים, למערכת ליבה וכדומה.`}
      </>
    ],
  },
  section2: {
    h: "ידע עדכני המתאים לדרישות העכשווית בהייטק",
    p: [
      "הביקוש הרב לגיוס מפתחי פול סטאק מעיד על מאפייני תעשיית ההייטק בתקופה הנוכחית. כיום, הדרישה היא למפתחים בעלי ידע וניסיון לפיתוח מקצה לקצה. כדי לפתח מקצה לקצה מפתח פול סטאק חייב לדעת לפתח בשפות שונות ועליו להכיר פלטפורמות מגוונות, מסדי נתונים ולהיות בעל יכולות פיתוח בצד שרת וצד לקוח.",
      "למעשה מפתח Full Stack הוא מתכנת בעל יכולות רבות, מעין מולטי טאלנט טכנולוגי, הוא מסוגל לאבחן ולאתר במהירות בעיות בצד הלקוח ובצד השרת, למצוא פתרונות טכנולוגיים לפיתוח מקצה לקצה, והוא בעל ראייה רחבה המעניקה לו פרספקטיבה מצוינת ומקיפה על הפרויקט.",
      <>
        {`כדי להשתלב `}
        <Link to={URL.HOME} active>בחברת פיתוח תוכנה מובילה</Link>
        {` מפתח פול סטאק נדרש ליכולות פיתוח בשפות תכנות כמו Python, Node.JS ,C# ,JAVA ,Javascript. כמו כן, עליו לשלוט במסדי נתונים כמו SQL ,MySQL, MongoDB ו- ובטכנולוגיות כגון: HTML5, CSS3.`}
      </>,
      "מפתחי פול סטאק חייבים לשלוט בסביבות פיתוח מתקדמות ועכשוויות, הם נדרשים לשלוט בסביבת הפיתוח ולהחזיק בארגז כלים רחב ושימושי הכולל ספריות, קודים מוכנים ורכיבים מתוכנתים המיועדים לפיתוח בשפות תכנות שונות. בנוסף, מפתחי פול סטאק עוסקים בפיתוח טכנולוגיות לקוח חדשניות ל-WEB, לכן עליהם להחזיק בידע ובכלים הנדרשים לבניית אתרים מתקדמים טכנולוגית בצד הלקוח ובצד השרת, ועליהם לשלוט בטכנולוגיות, פלטפורמות ושפות כמו React, Vue.js, Angular ועוד.",
    ],
  },
  section3: {
    h: "התמחות והתמקצעות בצד שרת וצד הלקוח",
    p: [
      "כפי שציינו בתחילת המאמר, פיתוח Full Stack עוסק בפיתוח מקצה לקצה, כלומר המתכנת חייב להיות בעל ידע וכלים לפיתוח בצד שרת ובצד הלקוח. התפקיד של מפתח פול סטאק נחשב למאתגר במיוחד, עליו לשלוט במספר רב של שפות תכנות, פלטפורמות וסביבות פיתוח, מסדי נתונים וכתיבת קוד. במהלך עבודתו, המפתח נדרש לשלב בין תחומי ידע, לפתח רכיבים תוכנות לצד לקוח וצד שרת, והוא עובד באופן עצמאי על הפיתוח, לכן עליו להיות בעל יכולות תכנון גבוהות מאוד לתכנון ולפיתוח של מערכות מקצה לקצה, והוא חייב להיות יצירתי. למרות שמפתחי פולק סטאק עובדים עצמאית, בחברות ההייטק עליהם לעבוד גם בצוותים, לפיכך מעבר למיומנויות ולכלים הטכנולוגיים עליהם לדעת לעבוד בצוות.",
      "ידע רחב ומעמיק בצד לקוח ובצד שרת מאפשר למפתחי Full Stack לספק מענה מקצועי למגוון רחב של דרישות ויישומים, והידע המקיף חוסך לא מעט עלויות עבור המעסיקים בהייטק. חברות הייטק מעדיפות לגייס מפתחים בעל ידע בפיתוח לצד לקוח ובפיתוח לצד שרת, יש תחרות גבוהה על הטאלנטים בתחום זה, והביקוש הרב למפתחי Full Stack  צפוי להימשך בשנים הקרובות.",
      "מעבר לידע ולכלים הנדרשים לפיתוח מקצה לקצה, מפתחי פול סטאק חייבים להיות מיומנים בארכיטקטורת אפליקציות WEB. לצורך תכנון ופיתוח יישום אינטרנט מתקדם ומורכב חשוב לדעת כיצד לכתוב קוד ולבנות אפליקציות להפרדה בין הקבצים, ויש להתייחס בתהליך הפיתוח לאירוח קובצי המדיה, לבנייה נכונה של מסד הנתונים, לתכנן היכן יבוצעו משימות חישוביות (בצד לקוח או בצד שרת), ולשלב בין הטכנולוגיות השונות כדי להבטיח יישום מורכב הפועל בצורה יעילה, מהירה ואמינה.",
    ],
  },
  section4: {
    h: "שגרת יומו של מפתח Full Stack",
    p: [
      <>
        {`מפתחי פול סטאק עוסקים במסגרת עבודתם השוטפת בפיתוח אתרי אינטרנט מורכבים, תוך התייחסות נכונה לחוויית המשתמש `}
        <Link to={URL.UI_UX_DESIGN_SERVICE} active>ולפיתוח ממשקי UI/UX</Link>
        {`, עליהם לפתח אתרים רספונסיביים המתאימים לכל סוגי המסכים ומערכות ההפעלה, ובנוסף הם עוסקים בפיתוח לצד שרת הכולל פיתוח רכיבי תקשורת עם בסיסי נתונים, קשר דו צדדי בין צד הלקוח לצד השרת, העברת מידע ושמירת נתונים בהתאם לפעילות המשתמש ועוד.`}
      </>,
      `פיתוח Full Stack עוסק בפיתוח מוצרים לחברות טכנולוגיה ובפיתוח אפליקציות ובניית אתרי אינטרנט מורכבים. שגרת היום של מפתחי פול סטאק נחשבת למאתגרת מאוד מאחר והתפקיד דינמי ומחייב גמישות ויצירתיות. התפקיד כולל אפיון מקצועי ויסודי של המוצר הטכנולוגי כדי להחליט כיצד לפתח אותו ובאליו שפות ופלטפורמות להשתמש.`,
      <>
        {`חברת Appsforce – בית תוכנה לפיתוח אפליקציות, פיתוח אתרים ופיתוח מוצרים לחברות טכנולוגיה, מספקת גם `}
        <Link to={URL.FULL_STACK_SERVICE} active>שירותי פיתוח Full Stack</Link>
        {` מקצה לקצה החל משלב העיצוב ואפיון ועד למימוש המוגמר הכולל Backend ו- Front End בטכנולוגיות החדישות ביותר. למידע נוסף וייעוץ בנושא שלבים ותהליכי הפיתוח אצלנו - הנכם מוזמנים `}
        <Link to={URL.CONTACT} active>ליצור איתנו קשר</Link>.
      </>,
    ]
  },
};